@import '@/styles/artifacts.scss';




































































.header-cell {
  align-items: center;
}
